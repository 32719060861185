.page-background {
    background-color: #000117;
    min-height: 100vh;
    padding-top: 50px;
}

.intro-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    margin-top: 150px;
}

.intro {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 50px;
}

.hi {
    color: #64ffda;
    font-size: 2.5rem;
    font-weight: 400;
    display: block;
}

.introName {
    font-size: 5rem;
    color: #ccd6f6;
    margin: 0;
}

.introTagline {
    font-size: 5.6rem;
    color: #8892b0;
    margin: 0;
}
 
.motivational-quote {
    display: flex;
    flex-direction: row;
    color: #ccd6f6;
    text-align: center;
    justify-content: center;
    width: 100%;
    padding-top: 150px;
}

.motivational-quote h1{
    font-size: 2.2rem;
    font-weight: 200;
    margin-left: 1rem;
    margin-right: 1rem;
}

.buttons{
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 100px;
    padding-top: 5%;
    cursor: pointer;
}

.inside-button{
    color: white;
    text-decoration: none;
}

.inside-button:hover{
    text-decoration: none;
}

.buttons-display {
    background: linear-gradient(to right, #009f70, #00bfcf);
    border: none;
    color: white;
    padding: 15px 32px;
    width: 200px;
    height: 50px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    text-decoration: none;
}

.image-1, .image-2, .image-3, .image-4 {
    position: absolute;
    border-radius: 50%;
    filter: brightness(75%);
    aspect-ratio: 1/1; /* Maintain square aspect ratio */
}

.image-1 {
    width: 15%;
    height: auto;
    right: 25%;
    top: -45%;
}

.image-2 {
    width: 10%;
    height: auto;
    right: 10%;
    top: -50%;
}

.image-3 {
    width: 8%;
    height: auto;
    top: 30%;
    right: 3%;
    transform: rotate(180deg);
    image-rendering: optimizeQuality;
}

.image-4 {
    width: 10%;
    height: auto;
    top: 65%;
    right: 15%;
}

.image-1::before, .image-2::before, .image-3::before, .image-4::before {
    content: "";
    position: absolute;
    top: -5px; 
    left: -5px;
    width: calc(100% + 10px);
    height: calc(100% + 10px);
    border-radius: 50%;
    background: linear-gradient(to right, #00ff94, #00dfff, #e361ff);
    z-index: -1;
}

.image-1::after, .image-2::after, .image-3::after, .image-4::after {
    content: "";
    position: absolute;
    top: -5px; 
    left: -5px;
    width: calc(100% + 10px);
    height: calc(100% + 10px);
    border-radius: 50%;
    background: linear-gradient(to right, #00ff94, #00dfff, #e361ff);
    z-index: -1;
    filter: blur(20px);
}

.image-1 img, .image-2 img, .image-3 img, .image-4 img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    display: block;
    object-fit: cover;
}

@media (max-width: 600px) {

    .image-2, .image-3, .image-4 {
        display: none;
    }

    .image-1 {
        display: flex;
        justify-content: center;
        width: 40%;
        margin-top: -15%;
        
    }

    .intro{
        padding-top: 3.5rem;
        padding-left: 13%;
    }

    .introTagline {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.4rem;
        font-weight: 600;
        color: #8892b0;
    }

    .hi {
        display: flex;
        justify-content: center;
        color: #64ffda;
        font-size: 1.2rem;
        font-weight: 500;
    }
    
    .introName {
        display: flex;
        justify-content: center;
        font-size: 1.6rem;
        color: #ccd6f6;
        font-weight: 500;
    }

    .motivational-quote h1{
        display: flex;
        justify-content: center;
        font-size: 1.6rem;
        font-weight: 500;
        margin-top:-1.5rem;
        padding-bottom:1.5rem;
    }

    .buttons-display {
        margin-left: 1rem;
        margin-right: 1rem;
    }
}

@media (min-width: 601px) and (max-width: 1366px) {
     .image-2, .image-3, .image-4 {
        display: none;
    }

    .image-1 {
        width: 20%;
        right: 5%;
    }

    .introTagline {
        font-size: 5rem;
        color: #8892b0;
    }

    .hi {
        color: #64ffda;
        font-size: 3rem;
    }
    
    .introName {
        font-size: 4rem;
        color: #ccd6f6;
        margin: 0;
    }
}

@media (min-width: 1367px){
   .introTagline {
       font-size: 5rem;
       color: #8892b0;
   }

   .hi {
       color: #64ffda;
       font-size: 3rem;
   }
   
   .introName {
       font-size: 4rem;
       color: #ccd6f6;
       margin: 0;
   }
}

@media (max-width: 900px) {
    .intro{
        display: flex;
        justify-content: center;
        text-align: center;
        padding-right: 2rem;
    }
    .buttons-display{
        padding-left: 1rem;
    }

    .image-1 {
        display: flex;
        justify-content: center;
        margin-right: 15px;
    }
}

@media(min-width: 500px) and (max-width: 550px){

    .intro{
        display: flex;
        justify-content: center;
        padding-right: 1rem;
        padding-left: 7.3rem;
    }

    .buttons-display{
        padding-left: 1rem;
    }

    .image-1 {
        display: flex;
        justify-content: center;
        width: 30%;
        margin-top: -60px;
        margin-right: 50px;
        
    }
}