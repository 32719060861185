.projects {
    padding-bottom: 11rem;
}

.f1-project{
    display: flex;
    justify-content: center;
    align-items:center;
    padding-left: 1rem;
    padding-bottom: 3rem;
}

.f1-container{
    text-align: center;

    padding-left: 4rem;
    width: 50%;
}

.f1-info{
    padding: 2rem;
}

.f1-info h2{
    display: flex;
    justify-content: center;
    color: aquamarine;
    padding-bottom: 1rem;
    font-size: 3rem;
}

.link{
    display: flex;
    justify-content: center;
    cursor: pointer;
}

.personal-website{
    display: flex;
    justify-content: center;
    align-items:center;
    padding-right: 1rem;
}

.personal-container{
    text-align: center;
    padding-right: 4rem;
    width: 50%;
}

.personal-info{
    padding: 2rem;
}

.personal-info h2{
    display: flex;
    color: aquamarine;
    justify-content: center;
    padding-bottom: 1rem;
    font-size: 3rem;
}

@media (max-width: 900px) {
    .f1-project {
        display: flex;
        flex-direction: column;
    }

    .f1-container{
       padding-left: 10px;
       padding-right: 10px;
       width: 100%;
       padding-top: 1.5rem
    }

    .personal-website {
        display: flex;
        flex-direction: column;
    }

    .personal-container{
       padding-left: 10px;
       padding-right: 10px;
       width: 100%;
       padding-top: 1.5rem;
       padding-bottom: 1.5rem
    }

    .personal-info{
        justify-content: center;
    }

    .personal-info h2{
        margin-left: 50%;
        margin-right: 45%;
    }

    .personal-info p{
        width: 100%;
        padding-left: 20px;
    }

 }