.carousel {
    position:relative;
    display:flex;
    justify-content: center;
    align-items: center;
    width: 40%;
    height: 40%;
    margin: auto;
}

.slide {
    border-radius: 0.5rem;
    box-shadow: 0px 0px 7px #666;
    width: 100%;
    height: 100%;
}

.slide-hidden {
    display: none;
}

.arrow{
    position:absolute;
    width: 2rem;
    height: 2rem;
    color: white;
    filter: drop-shadow(0px 0px 5px #555);
}

.arrow-left{
    left:1rem;
}

.arrow-right{
    right: 1rem;
}

.arrow:hover{
    cursor: pointer;
}

.indicators{
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 1rem;
}

.indicator {
    background-color: white;
    height: 0.5rem;
    width: 0.5rem;
    border-radius: 100%;
    border:none;
    outline: none;
    box-shadow: 0px 0px 5px #555;
    margin:0 0.2rem;
    cursor: pointer;
}

.indicator-inactive {
    background-color: grey;
}

@media (max-width: 900px) {
    .carousel{
       width: 95%;
       display: flex;
       justify-content: center;
       align-items: center;
       margin-left: 0%;
       margin-right: 0%;
    }
 }