.about {
    padding-top: 14rem;
    padding-bottom: 11rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.media-item {
    width: 40px;
    position: relative; 
    z-index: 1;
    height: 40px;
    cursor: pointer;
}

.media-item:hover {
    transform: scale(1.1);
    cursor: pointer;
}

.media {
    display: flex;
    flex-direction: row;
    gap: 50px;
    justify-content: center;
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.about-card {
    position: relative; 
    border: 5px solid;
    border-radius: 10px;
    border-image-source: linear-gradient(to right, #00ff94, #00dfff, #e361ff);
    border-image-slice: 1;
    width: 75%;
    justify-content: center;
    z-index: 1;
    box-shadow: 0 0 30px rgba(0, 255, 148, 0.5), 
                0 0 30px rgba(0, 223, 255, 0.5), 
                0 0 30px rgba(227, 97, 255, 0.5);
}

.about-card::before {
    content: "";
    position: absolute;
    top: -5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
    border-radius: inherit;
    border: 5px solid transparent;
    z-index: -1;
}

.about-card h2 {
    position: relative; 
    z-index: 1;
    color: aquamarine;
    font-weight: 800;
    padding-top: 2rem;
    display: flex;
    justify-content: center;
    font-size: 3rem;
}

.about-card p {
    display: flex;
    justify-content: center;
    position: relative; 
    font-size: 1.1rem;
    z-index: 1;
    padding: 0.5rem 0.5rem;
    color: white;
    font-weight: 600;
    padding-left: 2rem;
    padding-right: 1rem;
}
